import { graphql } from 'gatsby';
import React, { FunctionComponent, ReactElement } from 'react';
import FlexibleContent from '~/components/FlexibleContent';
import SEO from '~/components/Global/SEO';
import DisbursementsHero from '~/components/Hero/DisbursementsHero';
import Layout from '~/components/Layout';
import { DisbursementsPageQuery } from '../graphql-types';
interface DisbursementsProps {
  data: DisbursementsPageQuery;
}

const DisbursementsFC: FunctionComponent<DisbursementsProps> = ({
  data,
}): ReactElement => {
  const {
    title,
    disbursementsPage: { disbursementsHero },
    layouts,
  } = data.wpPage;
  return (
    <Layout>
      <SEO title={title} />
      <main>
        <DisbursementsHero hero={disbursementsHero} />
        <FlexibleContent pageName="disbursements" layouts={layouts} />
      </main>
    </Layout>
  );
};

export default DisbursementsFC;

export const query = graphql`
  query DisbursementsPage($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      disbursementsPage {
        disbursementsHero {
          subheading
          heading
          content
          button {
            url
            title
          }
          image {
            ...Image
          }
        }
      }
      layouts {
        layouts {
          __typename
          ... on WpPage_Layouts_Layouts_TwoColImageText {
            subheading
            heading
            headingWidth
            content
            contentWidth
            contentAlignment
            image {
              ...Image
            }
            imagePosition
            backgroundColour
            #  alertNotice
            #  noticeContent
            contentLink
            link {
              title
              url
            }
          }
          ... on WpPage_Layouts_Layouts_Compliance {
            subheading
            heading
            content
            feature {
              heading
              description
            }
          }
          ... on WpPage_Layouts_Layouts_OldWayNewWay {
            section {
              icon {
                ...Image
              }
              heading
              content
            }
          }
          ... on WpPage_Layouts_Layouts_Cta {
            heading
            content
            button {
              title
              url
            }
            image {
              ...Image
            }
            mobileImage {
              ...Image
            }
            imagePosition
          }
          ... on WpPage_Layouts_Layouts_RelatedPages {
            heading
            page {
              heading
              description
              link {
                title
                url
              }
              image {
                ...Image
              }
            }
          }
          ... on WpPage_Layouts_Layouts_TabsWithFwImage {
            subheading
            heading
            content
            tab {
              tabName
              image {
                ...Image
              }
            }
          }
          ... on WpPage_Layouts_Layouts_VettingAndValidation {
            subheading
            heading
            content
            charity {
              logo {
                ...Image
              }
              charityName
            }
          }
        }
      }
    }
  }
`;
