import { Link } from 'gatsby';
import React, { FunctionComponent } from 'react';
import Lottie from 'react-lottie-player';
import animationData from '../../data/animations/percent-disbursements-masthead.json';

interface DisbursementsHeroProps {
  hero: {
    subheading: string;
    heading: string;
    content: string;
    button: {
      url: string;
      title: string;
    };
    image: string;
  };
}

const DisbursementsHero: FunctionComponent<DisbursementsHeroProps> = ({
  hero: { subheading, heading, content, button },
}) => {
  return (
    <section className="masthead connect-masthead mt-12 pt-0 md:pt-20 md:my-0">
      <div className="container text-center relative z-20">
        <div className="font-medium text-red">{subheading}</div>
        <h1
          className="max-w-xl mx-auto"
          dangerouslySetInnerHTML={{ __html: heading }}
        />
        <div
          className="max-w-xl mx-auto text-navy mb-6"
          dangerouslySetInnerHTML={{ __html: content }}
        />
        <Link to={button?.url} className="btn">
          {button?.title}
        </Link>
      </div>

      <div className="max-w-screen overflow-hidden relative">
        <div className="-mx-24 md:-mx-20 xl:mx-0">
          <div className="relative z-10 mx-auto max-w-1600px">
            <Lottie animationData={animationData} play={true} />
          </div>
        </div>
        <div className="gradient-white-bottom" />
      </div>
    </section>
  );
};

export default DisbursementsHero;
